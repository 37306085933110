import React from "react";
import { Helmet } from 'react-helmet';
import Head from "../components/Head";
import PageContainer from "../components/PageContainer";

function IndexPage() {
  return (
      <>
        <Head />
        <PageContainer contentFullHeight disableBackground={false} />
      </>
  );
}

export default IndexPage;